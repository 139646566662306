import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/casino/lvbetcom/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.lvbet.com/',
  walletApiUrl: 'https://wallet.lvbet.com/',
  paymentApiUrl: 'https://payment.lvbet.com/',
  cmsApiUrl: {
    default: 'https://lvbet.com/'
  },
  cmsHeadlessApiUrl: 'https://cms.lvbet.com/',
  shadowStylesUrl: {
    default: 'https://lvbet-cms3.testowaplatforma123.net/shadow-css/'
  },
  cpSocketUrl1: 'wss://ws.lvbet.com/register',
  payAndPlayAuthorization: {
    username: 'paymentinternalapi',
    password: 'prOd,135.1E4;',
    // if we want generate authorization key, we can use window.btoa function ex. `basic ${window.btoa('username:password')}`
    authorizationKey: 'basic cGF5bWVudGludGVybmFsYXBpOnByT2QsMTM1LjFFNDs='
  }
};
