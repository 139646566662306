import { LadeskChatConfig } from '../types';

export const ladeskChatConfig: LadeskChatConfig = {
  perLangConfig: {
    en: {
      chatId: '6a3lm49x',
      chatIdForLoggedInUser: 'w4mceu1u'
    }
  },
  scriptUrl: 'https://supportpage.ladesk.com/scripts/track.js',
  extraUserDetailsFields: { DOB: 'birthDate' }
};
