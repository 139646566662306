import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  productType: ProductType.casino,
  brandI18Name: 'lvbetcom',
  brandCmsName: 'lvbetcom',
  brandName: 'LV BET',
  langs: ['en', 'br', 'es', 'de', 'pl', 'hu', 'fi', 'fr', 'vi'],
  cmsDevicePrefix: false,
  cmsBrandNamePrefix: false,
  cmsHeadlessEnabled: true,
  ssrDefaultCacheTime: 5 * 60,
  staticImagesUrl: 'https://static-assets.lvbet.com/images/'
};
