import { LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { basicInit, basicInitDeps, pwaInit, pwaInitDeps } from '@bcf-libs/app-utils/src/lib/basic-initializator';
import { routerInitAfterEnvSetup, routerInitAfterEnvSetupDeps } from '@bcf-libs/app-utils/src/lib/routes-initializator';
import { BCF_ROUTER_PROVIDER } from '@bcf-libs/ui/shared/src/lib/bcf-router/bcf-router-factory';
import { MODAL_EMBEDDED_CONTAINER } from '@bcf-libs/ui/shared/src/lib/modal-embedded-container/modal-embeddec-container.token';
import { TwaPathLocationStrategy } from '@bcf-shared-platform/path-location-strategy/twa-path-location-strategy';
import { piqTheme } from '@bcf-v2-configurators/tokens/piq-styles/lvbetcom/piq-theme';
import { PIQ_THEME } from '@bcf-v2-configurators/tokens/piq-styles/token';
import { initTwa, initTwaDeps } from '@bcf-v2-platforms/trusted-web-activity/twa-initializer';
import { ModalMainContainerComponent } from '@bcf-v2-ui-legacy-container-components-casino-lvbetcom/mobile-web/modal-main-container/modal-main-container.component';
import { ExternalRedirectorTwaService } from '@bcf-v2-ui-shared/services/external-redirector-twa.service';
import { ExternalRedirectorService } from '@bcf-v2-ui-shared/services/external-redirector.service';
import { TrailingSlashUrlSerializer } from '@bcf-v2-utilities/app-utils/trailing-slash-url-serializer';
import { provideI18nTranslations } from '@bcf-v2-utilities/i18n-translations/i18n-translations-provider';
import { settingsProviders } from '../../../mobile-web/src/app/settings-providers';
import { settingsProviders as settingsProvidersTwa } from './settings-providers';

const appCommonProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initTwa,
    deps: initTwaDeps,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: basicInit,
    deps: basicInitDeps,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: pwaInit,
    deps: pwaInitDeps,
    multi: true
  },
  provideI18nTranslations(),
  {
    provide: APP_INITIALIZER,
    useFactory: routerInitAfterEnvSetup,
    deps: routerInitAfterEnvSetupDeps,
    multi: true
  },
  BCF_ROUTER_PROVIDER,
  ...settingsProviders,
  ...settingsProvidersTwa,
  { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
  { provide: LocationStrategy, useClass: TwaPathLocationStrategy },
  { provide: ExternalRedirectorService, useClass: ExternalRedirectorTwaService },
  { provide: MODAL_EMBEDDED_CONTAINER, useValue: ModalMainContainerComponent },
  { provide: PIQ_THEME, useValue: piqTheme }
];

export const appBrowserProviders: (Provider | EnvironmentProviders)[] = [...appCommonProviders];
