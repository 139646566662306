import { Component, Injector, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppLoadedService } from '@bcf-v2-ui-shared/services/app-loaded.service';
@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private _injector: Injector,
    private _appLoadedService: AppLoadedService
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._appLoadedService.init();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (
          await import(
            /* webpackChunkName: "app-initializator" */ '@apps/projects/casino/lvbetcom/mobile-web/src/app/app-initializator'
          )
        ).LazyInitializatorCsLvbetcomMobileWeb
      )
      .init();
  }
}
